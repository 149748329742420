import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

export const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      "& fieldset": {
        borderRadius: 0,
      },
    },
    input: {
      padding: spacing(1.375, 1.5, 1.625),

      "&.MuiInputBase-inputSizeSmall": {
        padding: spacing(1, 1.75),

        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: spacing(1),
        },
        "&.MuiInputBase-inputAdornedEnd": {
          paddingRight: spacing(1),
        },
      },
    },
    inputAdornedStart: {
      flex: 1,
    },
    sizeSmall: {
      "&.MuiInputBase-adornedStart": {
        paddingLeft: spacing(1.5),
      },
      "&.MuiInputBase-adornedEnd": {
        paddingRight: spacing(1.5),
      },
    },
  },
};
