import type {
  DefaultError,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useLayoutEffect } from "react";

import { queryClient } from "./utils";

interface ResponseError extends Error {
  response: AxiosResponse;
}

const isResponseError = (err: any): err is ResponseError => err?.response;

// isPermissionError checks if the response status code is a 401 or 403
// which in this case represents a restricted resource.
const isPermissionError = (resp: AxiosResponse) => {
  return resp && (resp.status === 401 || resp.status === 403);
};

export const isAccessError = (err: any): boolean =>
  err && isResponseError(err) && isPermissionError(err.response);

/**
 * Wrapper for react-query's useQuery hook that will catch errors and reset data
 * back to the previous value
 *
 * This hook will NOT revert the data if an access error occurs, ensuring that
 * a change in permissions is properly propagated to the client
 *
 * Arguments and response are identical to useQuery
 *
 * @param {AnyQueryKey} key - key used for caching the fetch
 * @param {Function<Promise<Model>>} fetch - function that returns a Promise<Model>
 * @param {UseQueryOptions} options - options to pass to useQuery
 * @returns {UseQueryResult<Model>}
 */
export function useRevertableQuery<Model, Error = DefaultError>(
  options: UseQueryOptions<Model, Error>
): UseQueryResult<Model, Error> {
  const cachedData = queryClient.getQueryData(options.queryKey);

  const result = useQuery<Model, Error>(options);

  const isNotQueryAccessError = result.isError && !isAccessError(result.error);
  useLayoutEffect(() => {
    if (isNotQueryAccessError && cachedData) {
      queryClient.setQueryData(options.queryKey, cachedData);
    }
  }, [cachedData, isNotQueryAccessError, options.queryKey]);

  return result;
}
