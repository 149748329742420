import type { Components } from "@mui/material/styles";

import { spacing } from "../../options/spacing";

import { palette } from "./../../options/palette";

export const MuiTextField: Components["MuiTextField"] = {
  defaultProps: {
    variant: "outlined",
  },
  styleOverrides: {
    root: {
      border: `none`,
      backgroundColor: palette.common.white,
      "& .MuiInputBase-root.MuiAutocomplete-inputRoot": {
        padding: 0,
        "& input.MuiAutocomplete-input": {
          padding: spacing(1.375, 1.5, 1.625),
        },
      },
    },
  },
};
